<template lang="pug">
  FormModal(title="Customize Content", method="PUT", :endpoint="`collections/${collection_id}/content/${block_id}/customise`", :data="data", v-bind="$attrs", @success="onSuccess")
    InputGroup(label="Title - optional", name="title")
      TextInput(v-model="data.title", auto-focus)
    MarkdownInput(v-model="data.synopsis", label="Synopsis", name="synopsis", :suggested-min-length="10", :suggested-max-length="350", optional)
    InputGroup(label="Image - optional", name="featured_image", description="Max upload size: 10MB")
      ImageInput(v-model="data.featured_image")
    div(class="border-t mt-12 pt-12")
      div.form-inputgroup-label Original:
      div(class="mt-3 font-semibold text-primary") {{ original.title }}
      div(class="mt-1") {{ original.synopsis }}
      div(v-if="has_customisations", class="mt-4")
        a(@click="resetCustomisations", class="space-x-3")
          Icon(icon="undo")
          span Revert to original
</template>

<script>
import { get } from "lodash-es"
import MarkdownInput from "../../../components/Form/MarkdownInput.vue"

export default {
  components: {
    MarkdownInput
  },
  props: {
    block_id: {
      type: Number,
      required: true
    },
    collection_id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    has_customisations: false,
    data: {
      title: null,
      synopsis: null,
      featured_image: null
    },
    original: {
      title: "",
      synopsis: "",
      featured_image: null
    }
  }),
  async created() {
    let response = await this.$api.get(`collections/${this.collection_id}/content/${this.block_id}/customise`)
    this.has_customisations = get(response, "data.has_customisations")
    Object.assign(this.data, response.data)
    Object.assign(this.original, response.data.original)
  },
  methods: {
    async resetCustomisations() {
      let response = await this.$api.delete(`collections/${this.collection_id}/content/${this.block_id}/customise`)
      this.$notification.success(`Content successfully updated.`)
      this.$emit("close", response.data)
    },
    onSuccess(result) {
      this.$notification.success(`Content successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
